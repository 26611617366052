<script>
  import { mapActions, mapMutations } from 'vuex';
  import getThumbnail from '@/utils/getThumbnail.js';

  export default {
    data() {
      return {
        isToggleFavoriteLoading: false,
        thumbnailUrl: null,
      };
    },
    async created() {
      await this.setThumbnailUrl();
    },
    props: {
      course: {
        type: Object,
        required: true,
      },
    },
    methods: {
      ...mapMutations(['setErrorSnackbarMessage']),
      ...mapActions('CoursesModule', ['switchFavoriteState']),
      async setThumbnailUrl() {
        try {
          this.thumbnailUrl = await getThumbnail({
            url: this.course.coverUrl,
            folder: 'CoursesCovers',
          });
        } catch {
          this.thumbnailUrl = this.course.coverUrl;
        }
      },
      goToCourseDetailsRoute() {
        this.$router.push({
          name: 'CourseDetails',
          params: {
            courseId: this.course.id,
          },
        });
      },
      async toggleFavouriteStatus() {
        this.isToggleFavoriteLoading = true;
        try {
          await this.switchFavoriteState(this.course.id);
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isToggleFavoriteLoading = false;
        }
      },
    },
  };
</script>
