<template>
  <!-- one course -->
  <div
    class="my-2 course-container"
    @click="goToCourseDetailsRoute">
    <!-- cover image -->
    <div class="pa-2">
      <v-img
        height="112"
        class="course-image"
        :src="thumbnailUrl"></v-img>
    </div>
    <!-- title -->
    <v-row
      class="col-12 pt-1 pb-0"
      no-gutters
      justify="space-between">
      <div class="course-title">{{ course.title }}</div>

      <v-btn
        icon
        @click.stop="toggleFavouriteStatus"
        :loading="isToggleFavoriteLoading"
        :disabled="isToggleFavoriteLoading">
        <v-icon
          v-if="course.isFavorite"
          color="primaryColor"
          small
          >mdi-heart</v-icon
        >
        <v-icon
          v-else
          small
          >mdi-heart-outline</v-icon
        >
      </v-btn>
    </v-row>
    <v-row
      class="col-12 pt-1 pb-0"
      no-gutters
      justify="start">
      <div class="image-container pr-2">
        <v-img
          :src="course.audioUrl ? require('@/assets/course/audio.svg') : require('@/assets/course/video.svg')"></v-img>
      </div>
      <div class="course-duration-text">{{ course.duration }} min</div>
    </v-row>
    <v-row
      class="col-12 pt-1 pb-0"
      no-gutters
      justify="start">
      <div class="course-description">{{ course.description.slice(0, 48) }}...</div>
    </v-row>
  </div>
</template>

<script>
  import CourseGridCardMixin from '@/components/courses/course-grid-card/CourseGridCard.mixin.vue';
  export default {
    mixins: [CourseGridCardMixin],
  };
</script>

<style lang="css" scoped>
  .course-container {
    background: var(--v-offWhite-base);
    border-radius: 12px;
    height: 100%;
  }
  .course-image {
    border-radius: 12px;
  }
  .course-title {
    width: 70%;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    text-transform: uppercase;
    color: var(--v-offBlack-base);

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre-wrap;
  }
  .cours-duration-icon {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--v-brandGrey2-base);
  }
  .course-duration-text {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--v-brandGrey2-base);
  }
  .course-description {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #333333;
  }
  .image-container {
    width: 24px;
    height: 24px;
    transform: translateY(-1px) scale(0.8);
  }
  .image-container > img {
    width: 100%;
    height: 100%;
  }
</style>
